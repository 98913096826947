/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
/*import { useStaticQuery, graphql } from "gatsby"*/

import { Link } from "gatsby"
import Header from "./header"
import "./layout.css"
/*import { Z_BLOCK } from "zlib" (????)*/

import Hero from "../components/hero"
import About from "../components/about"

const Layout = ({ children }) => {
  /*const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)*/

  return (
    <div id="outer-container">
      <main id="page-wrap">
        <Header />

        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <Hero />
        </div>
        <About />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
        </div>
        <footer
          style={{
            fontSize: "0.7rem",
          }}
        >
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `20px 0`,
            }}
          >
            © {new Date().getFullYear()} All Rights Reserved. Newcon Projects
            Pty Ltd TA DCM Homes | VIC Builder’s
            Licence: CDB-U 60030
            <br />
            <Link to="terms">Terms and Conditions</Link> |
            <a href="https://www.iubenda.com/privacy-policy/41404817">
              Privacy Policy
            </a>
          </div>
        </footer>
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
