import React from "react"

import HeroImage from "../images/dcmhomes-logo-large.jpg"
import SEO from "./seo"

const Hero = () => (
  <div id="hero">
    <SEO title="Hero" />
    <img src={HeroImage} alt={`DCM Homes Logo`} />
  </div>
)

export default Hero
