import React from "react"

import SEO from "./seo"
import Workers from "../images/icons/workers.png"
import House from "../images/icons/house.png"
import Tools from "../images/icons/tools.png"

const About = () => (
  <div id="about">
    <SEO title="About" />
    <div id="about-wrapper">
      <h1>What We're All About</h1>
      <p>
        At DCM Homes we specialize in building high-quality, well-designed homes
        offering the best value throughout Melbourne’s Western and Northern
        suburbs.
      </p>
      <p>
        If you are a first home buyer, an investor or just looking for something
        different , we have a product for you! Our experienced team will guide
        you through the whole journey, from selecting a floor plan to organising
        the finances throughout the construction phase.
      </p>
      <p>
        DCM Homes has built fantastic relationships with land developers
        throughout Melbourne, allowing us to provide exceptional value full turn
        key House and Land packages.
      </p>
    </div>
    <div id={`icons`}>
      <img src={Workers} alt={`Workers`} />
      <img src={House} alt={`House`} />
      <img src={Tools} alt={`Tools`} />
    </div>
  </div>
)

export default About
