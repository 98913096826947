import React from "react"

import SimpleSlider from "../components/slider"
import SEO from "./seo"

const Suppliers = () => (
  <div id="suppliers">
    <SEO title="Suppliers" />
    <h1 style={{ textAlign: `center` }}>Our Suppliers</h1>
    <br />
    <SimpleSlider />
  </div>
)

export default Suppliers
