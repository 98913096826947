import React from "react"

export const ContactForm = () => {
  return (
    <div id="contactform">
      <form
        method="POST"
        name="DCM-Homes-Contact"
        id="DCM-Homes-Contact"
        data-netlify="true"
      >
        <main>
          <input type="hidden" name="form-name" value="DCM-Homes-Contact" />

          <div id="contact-name">
            <label for="name">
              Name <div id="requiredfield">*</div>
            </label>
            <br />
            <input
              required
              type="text"
              id="name"
              name="Name"
              placeholder="e.g: John Doe"
            />
          </div>
          <div id="contact-email">
            <label for="email">
              Email <div id="requiredfield">*</div>
            </label>
            <br />
            <input
              required
              type="email"
              id="email"
              class="required  email"
              name="Email"
              placeholder="e.g: john@gmail.com"
            />
          </div>
        </main>
        <aside>
          <div id="contact-message">
            <label for="message">
              Message <div id="requiredfield">*</div>
            </label>
            <br />
            <textarea
              required
              type="text"
              id="message"
              class="required"
              name="Message"
              rows="5"
              cols="50"
              maxlength="1000"
            ></textarea>
          </div>
        </aside>
        <div id="submit_button">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
