import React, { Component } from "react"
import Slider from "react-slick"

//logo imports
import Austral from "../images/Partner Logos/austral bricks logo.png"
import BD from "../images/Partner Logos/b&d logo.jpg"
import Bonaire from "../images/Partner Logos/bonaire logo.png"
import Caroma from "../images/Partner Logos/caroma logo.jpg"
import Chromagen from "../images/Partner Logos/chromagen logo.png"
import Corinthian from "../images/Partner Logos/corinthian-doors-logo.png"
import Dulux from "../images/Partner Logos/Dulux Logo.png"
import Gainsborough from "../images/Partner Logos/gainsborough logo.png"
import Monier from "../images/Partner Logos/monier.jpg"
import Unitex from "../images/Partner Logos/unitex logo.png"

// slider css
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: true,
      infinite: true,
      lazyload: true,
      speed: 400,
      autoplay: true,
      slidesToShow: 5,
      slidesToScroll: 1,
    }
    return (
      <div id="slider">
        <Slider {...settings}>
          <div>
            <h3>
              <img src={Austral} alt="Austral Bricks Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Bonaire} alt="Bonaire Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={BD} alt="B&D Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Caroma} alt="Caroma Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Chromagen} alt="Chromagen Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Corinthian} alt="Corinthian Doors Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Dulux} alt="Dulux Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Gainsborough} alt="Gainsborough Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Monier} alt="Monier Logo" />
            </h3>
          </div>
          <div>
            <h3>
              <img src={Unitex} alt="Unitex Logo" />
            </h3>
          </div>
        </Slider>
      </div>
    )
  }
}
