import React from "react"

import SEO from "./seo"
import ContactForm from "../components/contactform"

const Contact = () => (
  <div id="contact-wrapper">
    <h1>Contact Us</h1>
    <div id="contact">
      <SEO title="Home" />

      <ContactForm />
    </div>
  </div>
)

export default Contact
